/*! purgecss start ignore */
.hs-form-iframe {
    width: 100% !important;
}
body {
    @apply font-body font-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*! purgecss end ignore */

@media only screen and (max-width: 1024px) {
    .container {
        max-width: calc(100% - 24px) !important;
    }
}
.h-auto-important {
    height: auto !important;
}
.container {
    .container {
        padding: 0;
        max-width: 100% !important;
        min-width: 100% !important;
    }
}
.search-filter {
    box-shadow: none !important;
    outline: none !important;

    &:focus {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
}

.inslide-links-purple-midnight {
    a {
        @apply text-purple-midnight;
    }
}
.toggle-checkbox {
    &:checked {
        @apply right-0 transition-all;
        background-color: white !important ;
        background-image: none !important;
    }
    outline: none !important;
    border: none !important;
    background-color: white !important ;
    background-image: none !important;
    box-shadow: none !important;
}

.toggle-checkbox:checked + .toggle-label {
    @apply bg-purple;
}

.bullet-list {
    li {
        margin-bottom: 16px;
        padding-left: 32px;
        position: relative;
        &:before {
            content: '';
            width: 8px;
            height: 8px;
            border: 2px solid rgba(125, 95, 255, 1);
            position: absolute;
            top: 6px;
            left: 0;
            border-radius: 10px;
        }
    }
}

blockquote {
    @extend .lead;
    @apply my-6 pl-8 relative block;
    &:before {
        content: '“';
        width: 32px;
        height: 50px;
        @apply text-32 leading-32 absolute left-0 top-0 text-purple;
    }
}

.editor {
    &.opacity-70 {
        opacity: 1 !important;
        * {
            opacity: 0.7;
        }
        blockquote {
            opacity: 1;
            * {
                opacity: 1;
            }
        }
    }
}

.video-player {
    height: initial !important;
    width: 100% !important;
}

.legal-text-content {
    @media only screen and (min-width: 992px) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        column-gap: 50px;
    }
    ul {
        list-style: initial;
        padding-left: 40px;
        margin: 20px 0;
    }
    ol {
        margin: 0;
        list-style: decimal;
        ol {
            list-style: lower-alpha;
            ol {
                list-style: lower-roman;
            }
        }
        padding-left: 40px;
        margin: 20px 0;
        li {
            margin-top: 8px;
            padding-left: 5px;
        }
        ol {
            list-style-type: lower-alpha;
        }
    }
}
.lists {
    > li,
    ul li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        ul li {
            &:before {
                width: 10px;
                height: 10px;
                background-color: #7d5fff;
            }
        }
        ul,
        ol {
            padding-top: 10px;
            li {
                margin-bottom: 15px;
            }
        }
        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 10px;
            border: 2px solid #7d5fff;
            position: absolute;
            top: 6px;
            left: 0;
        }
    }
    ol {
        margin: 0;
        list-style: decimal;
        ol {
            list-style: lower-alpha;
            ol {
                list-style: lower-roman;
            }
        }
    }
}

// Animation (code is bottom of icon-grid comp)
// .rev-bg {
//     transform: scale(0, 1);
//     transform-origin: left center;
//     transition-delay: 0s !important;
// }

// .rev-img-wrap {
//     transform: translate(-101%, 0px);
//     transition-delay: 0s;
//     overflow: hidden;
// }
// .rev-img {
//     transform: translate(101%, 0px) scale(1.2);
//     transform-origin: right top;
//     transition-delay: 0ms;
// }
// .to-animate {
//     .rev-bg {
//         transform: scale(1, 1);
//         transition: all 800ms ease 0s !important;
//     }

//     .rev-img-wrap {
//         transition: all 800ms ease 0s;
//         transform: translate(0px, 0px);
//         transition-delay: 800ms !important;
//     }
//     .rev-img {
//         transition: all 800ms ease 0s;
//         transform: translate(0px, 0px);
//         transition-delay: 800ms;
//     }
// }

.two-col--description {
    ul {
        @apply list-disc list-outside ml-8 py-4;
    }
}

.no-scroll {
    height: 100%;
    overflow: hidden;
}

.mobile-overflow {
    background: #53565a;
    position: relative;
}
.mobile-overflow:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: #53565a;
    z-index: -1;
}

input::-webkit-search-cancel-button {
    display: none;
}

.freeform-content {
    @apply container py-12 lead lead--large opacity-70  mb-6;
    b {
        @apply py-2 block;
    }
    ul {
        @apply list-disc list-outside ml-8 py-4;
    }
    table {
        font-size: 0.8em;
    }
    .bwalignc {
        text-align: center;
        list-style-position: inside;
    }
    .bwalignl {
        text-align: left;
    }
    .bwalignr {
        text-align: right;
        list-style-position: inside;
    }
    .bwblockalignl {
        margin-left: 0px;
        margin-right: auto;
    }
    .bwcellpmargin {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .bwdoublebottom {
        border-bottom: double black 2.25pt;
    }
    .bwlistcircle {
        list-style-type: circle;
    }
    .bwlistdisc {
        list-style-type: disc;
    }
    .bwnowrap {
        white-space: nowrap;
    }
    .bwpadl0 {
        padding-left: 0px;
    }
    .bwpadl2 {
        padding-left: 10px;
    }
    .bwpadl4 {
        padding-left: 20px;
    }
    .bwpadr0 {
        padding-right: 0px;
    }
    .bwrowaltcolor0 {
        background-color: #c5d4fd;
    }
    .bwsinglebottom {
        border-bottom: solid black 1pt;
    }
    .bwtablemarginb {
        margin-bottom: 10px;
    }
    .bwuline {
        text-decoration: underline;
    }
    .bwvertalignb {
        vertical-align: bottom;
    }
    .bwvertalignm {
        vertical-align: middle;
    }
    .bwvertalignt {
        vertical-align: top;
    }
    .bwwidth1 {
        width: 1%;
    }
    .bwwidth100 {
        width: 100%;
    }
    .bwwidth12 {
        width: 12%;
    }
    .bwwidth71 {
        width: 71%;
    }
    .bw-subheadline-wrapper {
        @apply pb-8;
        font-size: 0.8em;
    }
}
div.long-form iframe {
    min-height: 1100px;
}
@media (min-width: 768px) {
    div.zero-padding {
        padding-bottom: 0px;
    }
}
div.zero-padding div.container div.mb-12 div.col-span-8 > div {
    display: flex;
    flex-direction: column;
}
div.zero-padding div.container div.mb-12 div.col-span-8 > div > div.col-span-2 {
    padding-left: 0px;
    padding-top: 1rem;
}
div.child-flex-1 > div {
    flex: 1;
}
div.child-flex-1 > div:nth-child(2) {
    padding-left: 2rem;
}
@media (max-width: 768px) {
    div.child-flex-1 > div:nth-child(2) {
        padding-left: 0rem;
    }
}
div.cta-center div.col-span-2 {
    align-self: center;
    padding-left: 0;
}
div.text-24 ul {
    font-size: 24px;
}
// custom class for full width wp core image
div.full-width-image img {
    width: 100%;
    height: auto;
}

// media query for partners page hero
@media (max-width: 375px) {
    .full-hyphen span {
        font-size: 32px;
    }
}
// custom class for half width testimonial
.half-width-testimonial p.slide--subtitle {
    color: #7d5fff;
    font-size: 0.85rem;
    letter-spacing: 0.15em;
    margin-bottom: 1rem;
}
.half-width-testimonial p span,
.half-width-testimonial div.slide--tname {
    //color: white;
    opacity: 0.7;
}

.testimonials--light p span,
.testimonials--light div.slide--tname {
    color: #1e1a34;
    opacity: 0.7;
}

// custom for partners-page
.title-text > div > div {
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
}
@media (min-width: 768px) {
    .title-text > div > div {
        font-size: 24px;
    }
}
.partners-page-title h3 {
    font-size: 30px;
    line-height: 38px;
}

div.partners-padding-top {
    padding-top: 3rem;
}

.cta-padding div.text-center {
padding: 2.875rem 0;
}
blockquote.client-quote {
    padding-left: 0;
}
blockquote.client-quote:before {
    left: -16px;
}
.client-info-text {
    color: rgba(30, 26, 52, 0.7);
}
.sidebar-border-y {
    border-top: 1px solid rgba(30, 26, 52, 0.2);
    border-bottom: 1px solid rgba(30, 26, 52, 0.2);
}
.sidebar-border {
    border-bottom: 1px solid rgba(30, 26, 52, 0.2); 
    padding: 1rem 0;
}
.sidebar-title,
.sidebar-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: .2px;
    text-align: left;
}
.sidebar-content {
    color: rgba(30, 26, 52, 0.6);
    padding-top: 0.5rem;
}
.alternative-bg {
    background: #F8F8F7
}