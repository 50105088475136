[class^='en-'],
[class*=' en-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'enfusion-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.en-Cost-Down:before {
    content: '\e964';
}
.en-Fund-Outgrow:before {
    content: '\e965';
}
.en-mail-thin:before {
    content: '\e95d';
}
.en-circle-x:before {
    content: '\e94e';
}
.en-target:before {
    content: '\e94f';
}
.en-time-machine:before {
    content: '\e950';
}
.en-trash-off:before {
    content: '\e951';
}
.en-lock-info:before {
    content: '\e952';
}
.en-lock:before {
    content: '\e953';
}
.en-maze:before {
    content: '\e954';
}
.en-microphone:before {
    content: '\e955';
}
.en-press-release:before {
    content: '\e956';
}
.en-private-equity:before {
    content: '\e957';
}
.en-research:before {
    content: '\e958';
}
.en-fund-launch:before {
    content: '\e959';
}
.en-hedge-hund:before {
    content: '\e95a';
}
.en-inst-asset-manager:before {
    content: '\e95b';
}
.en-family-office:before {
    content: '\e95c';
}
.en-circle-Info:before {
    content: '\e95e';
}
.en-circle-warning:before {
    content: '\e95f';
}
.en-event-replay:before {
    content: '\e960';
}
.en-export-2x4:before {
    content: '\e961';
}
.en-export-simple:before {
    content: '\e962';
}
.en-f-outgrow:before {
    content: '\e963';
}
.en-route:before {
    content: '\e90d';
}
.en-download:before {
    content: '\e921';
}
.en-check:before {
    content: '\e93b';
}
.en-agile-cloud:before {
    content: '\e900';
}
.en-api:before {
    content: '\e901';
}
.en-avatar:before {
    content: '\e902';
}
.en-bar-line-chart-cloud:before {
    content: '\e903';
}
.en-bar-line-chart:before {
    content: '\e904';
}
.en-blog:before {
    content: '\e905';
}
.en-brochure:before {
    content: '\e906';
}
.en-browser:before {
    content: '\e907';
}
.en-building:before {
    content: '\e908';
}
.en-calendar:before {
    content: '\e909';
}
.en-caret-down:before {
    content: '\e90a';
}
.en-case-study:before {
    content: '\e90b';
}
.en-chart-candle:before {
    content: '\e90c';
}
.en-checklist:before {
    content: '\e90e';
}
.en-circle-check:before {
    content: '\e90f';
}
.en-circle-web:before {
    content: '\e910';
}
.en-close:before {
    content: '\e911';
}
.en-cloud-arrow:before {
    content: '\e912';
}
.en-code:before {
    content: '\e913';
}
.en-coin-stack:before {
    content: '\e914';
}
.en-computer-bar-chart:before {
    content: '\e915';
}
.en-computer-chart:before {
    content: '\e916';
}
.en-computer-check:before {
    content: '\e917';
}
.en-computer-cloud:before {
    content: '\e918';
}
.en-cube:before {
    content: '\e919';
}
.en-database-cloud:before {
    content: '\e91a';
}
.en-database-double:before {
    content: '\e91b';
}
.en-database-triple:before {
    content: '\e91c';
}
.en-devices:before {
    content: '\e91d';
}
.en-dollar-clipboard:before {
    content: '\e91e';
}
.en-donut-chart:before {
    content: '\e91f';
}
.en-dot-chart-cloud:before {
    content: '\e920';
}
.en-facebook:before {
    content: '\e922';
}
.en-filter:before {
    content: '\e923';
}
.en-flexible:before {
    content: '\e924';
}
.en-gear-:before {
    content: '\e925';
}
.en-gear-cloud:before {
    content: '\e926';
}
.en-gear-code:before {
    content: '\e927';
}
.en-gear-web:before {
    content: '\e928';
}
.en-gear:before {
    content: '\e929';
}
.en-it-tech:before {
    content: '\e92a';
}
.en-line-chart-cloud:before {
    content: '\e92b';
}
.en-link-bold:before {
    content: '\e92c';
}
.en-link:before {
    content: '\e92d';
}
.en-linkedin:before {
    content: '\e92e';
}
.en-long-arrow:before {
    content: '\e92f';
}
.en-mail:before {
    content: '\e930';
}
.en-modules:before {
    content: '\e931';
}
.en-money-sheet:before {
    content: '\e932';
}
.en-news:before {
    content: '\e933';
}
.en-phone:before {
    content: '\e934';
}
.en-pie-bar-chart:before {
    content: '\e935';
}
.en-pie-chart:before {
    content: '\e936';
}
.en-play:before {
    content: '\e937';
}
.en-refresh:before {
    content: '\e938';
}
.en-report-pie-chart:before {
    content: '\e939';
}
.en-ribbon:before {
    content: '\e93a';
}
.en-search-checklist:before {
    content: '\e93c';
}
.en-search:before {
    content: '\e93d';
}
.en-server-1:before {
    content: '\e93e';
}
.en-server-2:before {
    content: '\e93f';
}
.en-share:before {
    content: '\e940';
}
.en-sheet-check:before {
    content: '\e941';
}
.en-sheet-technical:before {
    content: '\e942';
}
.en-shield:before {
    content: '\e943';
}
.en-stack:before {
    content: '\e944';
}
.en-table-2x4:before {
    content: '\e945';
}
.en-table-check:before {
    content: '\e946';
}
.en-table-dollar:before {
    content: '\e947';
}
.en-table-simple:before {
    content: '\e948';
}
.en-twitter:before {
    content: '\e949';
}
.en-upgrade:before {
    content: '\e94a';
}
.en-video:before {
    content: '\e94b';
}
.en-web-diagram:before {
    content: '\e94c';
}
.en-whitepaper:before {
    content: '\e94d';
}
.en-analytics_risks:before {
    content: '\e966';
}

.en-connectivity:before {
    content: '\e967';
}

.en-data_service:before {
    content: '\e968';
}

.en-electronic_training:before {
    content: '\e969';
}

.en-fund_admin_middle_office:before {
    content: '\e96a';
}

.en-high_touch_algo_trading:before {
    content: '\e96b';
}

.en-other_software_service:before {
    content: '\e96c';
}

.en-outsourced_operations:before {
    content: '\e96d';
}

.en-post_trade_affirmation:before {
    content: '\e96e';
}

.en-prime_brokers_custodian:before {
    content: '\e96f';
}

.en-prime_executive_brokers:before {
    content: '\e970';
}

.en-regulatory_compliance:before {
    content: '\e971';
}